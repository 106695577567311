body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #efefef !important;
  margin: 0;
  padding: 0;
}

#root
{
  width: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover
{
  //color: green !important;
}
.nav-pills .nav-link {
  display: block;
  padding: 0.5rem 5% !important;
}
.my-menu
{
  width: 10%;
  background: #fff;
  list-style-type: none;
  border-radius: 5px;
  padding: 1em;
  //padding-left: 0px;
  display: none;
  color: #333;
}
.authLink
{
  color: green;
  text-decoration: none;
}
.authLink:hove
{
  color: green !important;
  text-decoration: underline !important;
}
.welcome
{
  margin-bottom: 2em;
}
.j-tracker
{
  color: #848f8e;
  //margin-top: 112em;
}
.contentHeader
{
  margin: 0px !important;
  width: 100%;
  height: 50px;
  background: #fff;
  position: relative;
  z-index: 100;
  //margin-left: 2em;
}
.TaskContentHeading
{
  padding-top: .3em;
  padding-bottom: .5em;
  margin-left: 4%;
}
.maiSecNave
{
  margin: 0px auto;
  //margin-left: 2em;
  padding: 2em 2%;
  background: #fff;
  color: #666;
  cursor: pointer;
}
.maiSecNaveLink
{
  margin-left: 2%;
}
.maiSecNave a
{
  color: #666;
  text-decoration: none;
}
.sidebar
{
	height: 100vh;
	//width: 180px;
  width: 13%;
	position: fixed;
  z-index: 1500;
}

.btmNav
 {
  position: relative !important;
  display: none !important;
  //opacity: 0;
 }

 @media  screen and (max-width:800px){
       
   .sidebar
   {
      position: relative !important;
      display: none !important;
      //opacity: 0;
   }

   .progressBar
   {
      display: none;
   }

   .btmNav
   {
    position: fixed !important;
    display: block !important;
    opacity: 1;
   }

}

.mySideDropDown
{
  margin-bottom: 2em;
  margin-left: 2em;
}
.main-sec
{
  margin-left: 4%;
  //width: 97%;
  //margin: 0em auto;
  margin-top: 0em !important;
}


@media screen and (max-width:800px){

      .main-sec
      {
        margin-left: 0%;
      }
}


.TableArea, .dashboardArea
{
  width: 96%;
  margin: 2em auto;
  margin-left: 2%;
}
.authForms
{
  width: 30%;
  margin: 8em auto;
  background: #fff;
  padding: 2em;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}

@media  screen and (max-width:800px){
       
   .authForms
    {
      width: 90%;
    }
}

.attachImg
{
  margin-left: 2em;
  margin-top: 2em;
  cursor: pointer;
}
.authForms2
{
  width: 30%;
  margin: 2em auto;
  background: #fff;
  padding: 2em;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}

@media  screen and (max-width:800px){
       
   .authForms2
    {
      width: 90%;
    }
}

.myFormInput
{
  padding: .2em;
  border-radius: 5px;
  border: 1px solid #cfcfcf;
  width: 100%;
}

.btn-filter
{
  border: none;
  padding: .3em 2em;
  border-radius: 5pxl
}

.myContainer {
  position: relative;
  width: 100%;
  height: 100vh; /* Make the container take the full viewport height */
}

.fixed-button {
    position: fixed;
    bottom: 20px; /* Adjust the distance from the bottom as needed */
    right: 20px; /* Adjust the distance from the right as needed */
    padding: 10px 20px;
    //background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 2000;
}

@media screen and (max-width:800px){

      .fixed-button {
        bottom: 80px; /* Adjust the distance from the bottom as needed */
        right: 20px; /* Adjust the distance from the right as needed */
    }
}

.myMobileNavLink
{
  color: #efefef;
  text-decoration: none;
}

.myMobileNavLink .active
{
  color: green;
}

.icone
{
  max-width: 80%;
}

.myMobileNavLink:hover
{
  color: #afafaf;
}

.my-link-card
{
  text-decoration: none;
}
.u:visited
{
  text-decoration: none;
}

.tableCard
{
  padding: 2em;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  background: #fff;
  //margin-top: 5em;
  cursor: pointer;
  margin-bottom: 2em;
  height: 12em;
  //width: 10%;
  //overflow: auto;
}
.tableCard2
{
  padding: 2em;
  //border: 1px solid #cfcfcf;
  border-radius: 5px;
  background: #fff;
  //margin-top: 5em;
  cursor: pointer;
  margin-bottom: 2em;
  //width: 100%;
  width: 100%;
  overflow: auto;
}
.btn-extn
{
  position: fixed;
  bottom: 48px; /* Adjust the distance from the bottom as needed */
  right: 22px; /* Adjust the distance from the right as needed */
  z-index: 700;
  background: #fff;
  padding: 20px 20px;
  cursor: pointer;
  display: none;
  z-index: 700;
  margin-bottom: 10px;
  width: 131px;
  color: #000;
}
.btn-extnJobCard
{
  position: absolute;
  top: 35px; /* Adjust the distance from the bottom as needed */
  right: -6px; /* Adjust the distance from the right as needed */
  z-index: 700;
  background: #fff;
  padding: 5px 10px;
  cursor: pointer;
  display: none;
  z-index: 700;
  margin-bottom: 10px;
  //border-bottom: 1px solid grey;
  width: 86px;
  color: #000;
}
.btn-extnJobScope
{
  position: absolute;
  top: 60px; /* Adjust the distance from the bottom as needed */
  right: -6px; /* Adjust the distance from the right as needed */
  z-index: 700;
  background: #fff;
  padding: 5px 10px;
  cursor: pointer;
  display: none;
  z-index: 700;
  margin-bottom: 10px;
  width: 86px;
  color: #000;
}
.btn-assessment
{
  position: absolute;
  top: 86px; /* Adjust the distance from the bottom as needed */
  right: -6px; /* Adjust the distance from the right as needed */
  z-index: 700;
  background: #fff;
  padding: 5px 10px;
  cursor: pointer;
  display: none;
  z-index: 700;
  margin-bottom: 10px;
  width: 86px;
  color: #000;
}
.mainPrintBtn
{
  position: absolute;
}
.ulDropDownPrint
{
  position: absolute;
  z-index: 10000000;
  list-style-type: none;
  list-style-position: outside;
  margin: none !important;
  padding: none !important;
  //display: none;
  -webkit-padding-start: 0;
}

@media screen and (max-width:800px){

      .btn-extn
      {
        bottom: 108px; /* Adjust the distance from the bottom as needed */
        right: 22px; /* Adjust the distance from the right as needed */
      }
    }

.myNav
{
  text-decoration: none;
  color: green;
}
.myNav:hover
{
  color: green;
  text-decoration: underline;
}
.editForm
{
  background: #fff;
  padding: 2em;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  border-top: none;
}

.fixed-button:hover .btn-extn
{
  display: block;
}
.text-job
{
  color: #848f8e;
}
.tableCard .icon
{
  font-size: 2em;
  margin-bottom: 1em;
  color: green !important;
}
.icon-top
{
  font-size: 2em;
  margin-top: .4em;
  cursor: pointer;
  //color: green !important;
}
.myOverlay
{
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .3);
  z-index: 2500;
}
.myModal
{
  margin: 1% auto;
  background: #efefef;
  width: 50%;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 2em;
  text-align: left;
}

@media screen and (max-width:800px){

    .myModal
    {
      margin: 1% auto;
      background: #efefef;
      width: 90%;
      border: 1px solid grey;
      border-radius: 5px;
      padding: 2em;
      text-align: left;
    }
}

.myMobileNav
{
  width: 100%;
  margin: 0px auto !important;
}

.myOverlay .myModal strong
{
  float: right;
  cursor: pointer;
}
.leftArrow
{
  float: left !important;
  cursor: pointer;
}

.myOverlay .myModal strong:hover
{
  color: #666;
}
.nav-pills .tasksSubLinkStyles .my-nav-link.active
{
  color: #fff;
  //background-color: green;
  border-left: 5px solid green;
}
.tasksSubLinkStyles li
{
  text-decoration: none;
  padding: .5em 1em;
  padding-top: 0px;
  padding-bottom: 1em;
  padding-left: 0px;
}
.tasksSubLinkStyles li:first-child
{
  padding-top: 1em;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: green !important;
}
.btn-success {
  color: #fff;
  background-color: green !important;
  border-color: green !important;
}
.btn-success:hover {
  color: #fff;
  background-color: green !important;
  border-color: green !important;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: green !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 125, 125, 0.25) !important;
}
.taskActive
{
  border-left: 5px solid blue;
}
.taskComplete
{
  border-left: 5px solid green !important;
}
.tasksSubLink li
{
  opacity: 0;
  display: none;
  transition: opacity 12s ease-in-out;
}
.tasksSubLinkStyles 
{
  margin-left: 1em;
  background: #efefef;
  color: #333;
  border-radius: 5px;
  //margin-top: 1em;
  //padding: 1em;
  padding-left: 0px;
  //margin-bottom: 1em;
  transition: * 12s;
}
.page-item:first-child{
  display: none !important;
}
.page-item:last-child{
  display: none !important;
}
.my-nav-link
{
  text-decoration: none;
  padding-left: 1.5em;
}

@media (min-width: 1560px) {
    .main-sec
    {
      margin-top: 3em;
      //margin-left: 120em;
      //width: 95%;
      //margin: 3em auto;
    }
}

@media (min-width: 992px) and (max-width : 1200px){

    .main-sec
    {
      margin-top: 3em;
      //margin-left: 120em;
      //width: 95%;
      //margin: 3em auto;
    }
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: green !important;
  background-color: #fff;
  border: 1px solid #dee2e6;
  //z-index: 3001;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  background-color: green;
  border-color: green;
}
.nav-link
{
  color: green !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057 !important;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
    background: green !important;
    border-color: green !important;
}

.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text, .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
    color: green !important;
}

.e-schedule .e-month-view .e-date-header-wrap table td.e-current-day, .e-schedule .e-month-agenda-view .e-date-header-wrap table td.e-current-day {
    color: green !important;
}

.e-schedule .e-month-view .e-current-date .e-date-header, .e-schedule .e-month-agenda-view .e-current-date .e-date-header {
    background: green !important;
}

.e-schedule .e-vertical-view .e-header-cells.e-current-day {
    color: green !important;
}
.e-schedule .e-agenda-view .e-appointment {
    border-left: 3px solid green !important;
}
.e-calendar .e-content td.e-selected span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
    background-color: green !important;
}

.e-input-group input.e-input:focus, .e-input-group.e-control-wrapper input.e-input:focus, .e-input-group textarea.e-input:focus, .e-input-group.e-control-wrapper textarea.e-input:focus {
    border-color: #ccc;
    box-shadow: 0 0 0 0.2rem rgba(0, 125, 125, 0.25) !important;
    border-color: green !important;
}





$lightGrey: #F2F2F2;
$darkGrey: #3B3B3B;

body {
  align-content: center;
  background: $lightGrey;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.content {
  margin: 0% auto;
  align-items: center;
  background-color: $darkGrey;
  display: flex;
  height: 300px;
  margin-top: 10%;
  width: 600px;
  justify-content: center;
}

.spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.spinner div {
  transform-origin: 40px 40px;
  animation: spinner 1.2s linear infinite;
}
.spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

.loading-text
{
  color: #fff;
  margin-top: -8%;
}

@keyframes spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.textArea{
  width: 96%;
  margin: 2em auto;
  margin-left: 2%;
  background: #fff;
  padding: 2em;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}

.containerBadge{
  //height: '50px';
  //width: '50px';
  //display: 'inline-block';
  margin-top: 1em;
  margin-left: 2.5em;
  //backgroundColor: 'gray';
}